export const ADD_VISITORS = 'agentsPwa/Agenda/ADD_VISITORS';
export const CANCEL_VISIT = 'agentsPwa/Agenda/CANCEL_VISIT';
export const CANCEL_POOL_VISIT = 'agentsPwa/Agenda/CANCEL_POOL_VISIT';
export const CANCEL_VISIT_REASONS = 'agentsPwa/Agenda/CANCEL_VISIT_REASONS';
export const SEND_OFFER = 'agentsPwa/Agenda/SEND_OFFER';
export const SEND_INTRODUCTION = 'agentsPwa/Agenda/SEND_INTRODUCTION';
export const OPEN_CANCEL_REASON_DIALOG =
  'agentsPwa/Agenda/OPEN_CANCEL_REASON_DIALOG';
export const CLOSE_CANCEL_REASON_DIALOG =
  'agentsPwa/Agenda/CLOSE_CANCEL_REASON_DIALOG';
export const FETCH_AGENDA_VISITS = 'agentsPwa/Agenda/FETCH_AGENDA_VISITS';
export const CLONE_VISIT = 'agentsPwa/Agenda/CLONE_VISIT';
export const OPEN_FOLLOW_UP_DIALOG = 'agentsPwa/Agenda/OPEN_FOLLOW_UP_DIALOG';
export const CLOSE_FOLLOW_UP_DIALOG = 'agentsPwa/Agenda/CLOSE_FOLLOW_UP_DIALOG';
export const OPEN_FINISH_VISIT = 'agentsPwa/Agenda/OPEN_FINISH_VISIT';
export const CLOSE_FINISH_VISIT = 'agentsPwa/Agenda/CLOSE_FINISH_VISIT';

export const CLEAR_FUP_DATA = 'agentsPwa/Agenda/CLEAR_FUP_DATA';

export const CHANGE_TAB = 'agentsPwa/Agenda/CHANGE_TAB';
export const AGENDA_PAGE_VIEWED_ANALYTICS =
  'agentsPwa/Agenda/AGENDA_PAGE_VIEWED_ANALYTICS';
export const AGENDA_NEW_VISIT_CLICKED_ANALYTICS =
  'agentsPwa/Agenda/AGENDA_NEW_VISIT_CLICKED_ANALYTICS';
export const AGENDA_VISIT_CANCELLATION_CONFIRMED =
  'agentsPwa/Agenda/VisitDetail/AGENDA_VISIT_CANCELLATION_CONFIRMED';
export const AGENDA_VISIT_CANCEL_PAGE_VIEWED =
  'agentsPwa/Agenda/VisitDetail/AGENDA_VISIT_CANCEL_PAGE_VIEWED';
export const AGENDA_VISIT_CARD_ACTION_CLICKED_ANALYTICS =
  'agentsPwa/Agenda/AGENDA_VISIT_CARD_ACTION_CLICKED_ANALYTICS';
export const NEW_VISIT_SCHEDULE_DATE =
  'agentsPwa/Agenda/NEW_VISIT_SCHEDULE_DATE';
export const CLONE_VISIT_TO_FOLLOW_UP =
  'agentsPwa/Agenda/CLONE_VISIT_TO_FOLLOW_UP';
export const OPEN_NEW_VISIT_DIALOG = 'agentsPwa/Agenda/OPEN_NEW_VISIT_DIALOG';
export const CLOSE_NEW_VISIT_DIALOG = 'agentsPwa/Agenda/CLOSE_NEW_VISIT_DIALOG';
export const OPEN_RESCHEDULE_VISIT_DIALOG =
  'agentsPwa/Agenda/OPEN_RESCHEDULE_VISIT_DIALOG';
export const OPEN_VISIT_CONFIRMATION_DIALOG =
  'agentsPwa/Agenda/OPEN_VISIT_CONFIRMATION_DIALOG';
export const CLOSE_VISIT_CONFIRMATION_DIALOG =
  'agentsPwa/Agenda/CLOSE_VISIT_CONFIRMATION_DIALOG';
export const CLOSE_RESCHEDULE_VISIT_DIALOG =
  'agentsPwa/Agenda/CLOSE_RESCHEDULE_VISIT_DIALOG';
export const OPEN_CANCEL_VISITOR_DIALOG =
  'agentsPwa/Agenda/OPEN_CANCEL_VISITOR_DIALOG';
export const OPEN_RESCHEDULE_VISIT_POOL_DIALOG =
  'agentsPwa/Agenda/OPEN_RESCHEDULE_VISIT_POOL_DIALOG';
export const CLOSE_POOL_ACTIONS_DIALOG =
  'agentsPwa/Agenda/CLOSE_POOL_ACTIONS_DIALOG';
export const FETCH_HOUSE_DETAILS = 'agentsPwa/Agenda/FETCH_HOUSE_DETAILS';
export const UNPUBLISH_LISTING = 'agentsPwa/Agenda/UNPUBLISH_LISTING';
export const FETCH_VISIT_CHECKINS = 'agentsPwa/Agenda/FETCH_VISIT_CHECKINS';
export const UPDATE_CLIENT_TAB_STATE =
  'agentsPwa/Agenda/UPDATE_CLIENT_TAB_STATE';
export const RESET_CLIENT_TAB_STATE = 'agentsPwa/Agenda/RESET_CLIENT_TAB_STATE';
export const FETCH_INITIAL_AGENDA_VISITS =
  'agentsPwa/Agenda/FETCH_INITIAL_AGENDA_VISITS';
