import { LIMIT_OF_DAYS } from 'config';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { List } from 'immutable';
import moment from 'moment';
import { FILTERS_MAP_DATE } from 'containers/Filters/enums';
import { EPOCH_PATH_CONSTANT_FACTOR } from 'utils/constants';
import { capitalizeFirstLetter } from 'utils/string';
import { FULLDAY_SCHEDULE } from 'containers/Agenda/constants';
import { VISIT_TIMES } from 'enums/visitTimes';

const dateISOFormat = 'YYYY-MM-DD';
const timeZoneOffsetInMilieconds = new Date().getTimezoneOffset() * 60000;

/**
 * Group the elements by date keeping the order
 * @param {array} elements - any element with day{string} property
 * @returns {List} a List with grouped arrays
 */
export const groupElementByDate = (elements) =>
  List(elements)
    .groupBy((element) => element.day)
    .toList();

/**
 * Receive number of days ago that you need
 * @param {int} numberOfDays
 * @returns {date} containing the date before the number of days received
 */
export const getDaysAgo = (numberOfDays = LIMIT_OF_DAYS) => {
  const currentDay = new Date();

  return new Date(
    currentDay.getFullYear(),
    currentDay.getMonth(),
    currentDay.getDate() - numberOfDays,
    0,
    0,
    0,
    0,
  );
};

/**
 * Return the difference (in days) from a given start date
 * @param {string} tab
 * @param {object} filters
 * @returns {date}
 */
export const getStartDate = (tab, filters) => {
  const dateOption = filters[`${tab}-filters`].selected;

  const daysAgo = FILTERS_MAP_DATE[dateOption].value;

  return getDaysAgo(daysAgo);
};

/**
 * Maps the dates from a given date (or today) to the specified number of days
 * @param {number} days Number of days to return
 * @param {Date} [startDate=new Date()]
 * @param {number|number[]} [weekdaysToSkip=[]] Weekday(s) to skip as numbers from 0 (sunday) to 6 (saturday)
 * @returns {Date[]} The dates in the range (all set to midnight)
 */
export const getDateRange = (
  days,
  startDate = new Date(),
  weekdaysToSkip = [],
) => {
  const dates = [];
  const baseDate = new Date(startDate);

  baseDate.setHours(0, 0, 0, 0); // return all dates at midnight
  const weekdays = Array.isArray(weekdaysToSkip)
    ? weekdaysToSkip
    : [weekdaysToSkip];

  while (dates.length < days) {
    const weekday = baseDate.getDay();

    if (!weekdays.includes(weekday)) {
      dates.push(new Date(baseDate));
    }

    baseDate.setDate(baseDate.getDate() + 1);
  }

  return dates;
};

export const formatDateToPattern = (date, pattern) =>
  moment(date).format(pattern);

/**
 * Format a date object to be the pattern "YYYY-MM-DD"
 * @param {Date}
 * @returns {string} The date formatted
 */
export const formatDateYearMonthDayPattern = (date) =>
  formatDateToPattern(date, dateISOFormat);

/**
 * Format a date object to be the pattern "DD/MM/YYYY"
 * @param {Date}
 * @returns {string} The date formatted
 */
export const formatDateDayMonthYearPattern = (date) =>
  formatDateToPattern(date, 'DD/MM/YYYY');

/**
 * Format a date object to be the pattern "DD/MM/YYYY HH:mm"
 * @param {Date}
 * @returns {string} The date formatted
 */
export const formatDateDayMonthYearTimePattern = (date) =>
  formatDateToPattern(date, 'DD/MM/YYYY HH:mm');

export const formatDateDayMonthPattern = (date) =>
  formatDateToPattern(date, 'DD/MM');

/**
 * Returns a date format to iso time (HH:MM:SS)
 * @param {Date}
 * @returns {String} The date formatted
 */
export const formatISOTime = (date) => date.toTimeString().substr(0, 5);

/**
 * Creates a epoch from Date
 * @param {Date}
 * @returns {number} The date provided in epoch format
 */
export const getEpochFromDate = (startTime, visitDate) => {
  const date = formatDateYearMonthDayPattern(visitDate);
  const dateTime = `${date}T${startTime}`;
  const momentDate = moment(dateTime);

  return momentDate.valueOf();
};

/**
 * Returns a date from epoch value
 * @param {number}
 * @returns {Date} The date corresponding to the epoch format
 */
export const getDateFromEpoch = (epoch) => {
  const epochValue = parseInt(epoch, 10);
  const momentDate = moment(epochValue);

  return momentDate.toDate();
};

/**
 * Returns a boolean which indicates if the received date is todays date
 * @param {Date}
 * @returns {Boolean}
 */
export const isDateToday = ({ date }) => {
  const selectedDateIso = moment(date).format(dateISOFormat);
  const currentDateIso = moment().format(dateISOFormat);

  return selectedDateIso === currentDateIso;
};

export const isDateTomorrow = ({ date }) => {
  const selectedDateIso = moment(date).format(dateISOFormat);
  const tomorrowDateIso = moment().add(1, 'days').format(dateISOFormat);

  return selectedDateIso === tomorrowDateIso;
};

export const subtractDays = (days, date) => dayjs(date).subtract(days, 'days');

/**
 * Returns a boolean which indicates if the received date is yesterday's date
 * @param {Date}
 * @returns {Boolean}
 */
export const isDateYesterday = ({ date }) => {
  const yesterday = subtractDays(1, moment());
  return moment(date).format(dateISOFormat) === yesterday.format(dateISOFormat);
};

export const extractDayMonth = (date) => ({
  day: moment(date).format('DD'),
  month: moment(date).format('MM'),
});

export const formatDateToCalendarPattern = (date, intl) => {
  if (!date) {
    return '';
  }
  const momentDate = formatDateYearMonthDayPattern(date);
  const options = { weekday: 'short', month: 'short', day: 'numeric' };
  return capitalizeFirstLetter(intl.formatDate(moment(momentDate), options));
};

export const formatToDatePickerPattern = (
  date,
  intl,
  yesterdayMessage,
  todayMessage,
  tomorrowMessage,
) => {
  if (!date) {
    return '';
  }
  const momentDate = formatDateYearMonthDayPattern(date);
  if (isDateYesterday({ date })) {
    const options = { month: '2-digit', day: '2-digit' };
    return `${yesterdayMessage}, ${intl.formatDate(
      moment(momentDate),
      options,
    )}`;
  }
  if (isDateToday({ date })) {
    const options = { month: '2-digit', day: '2-digit' };
    return `${todayMessage}, ${intl.formatDate(moment(momentDate), options)}`;
  }
  if (isDateTomorrow({ date })) {
    const options = { month: '2-digit', day: '2-digit' };
    return `${tomorrowMessage}, ${intl.formatDate(
      moment(momentDate),
      options,
    )}`;
  }

  const options = { weekday: 'long', month: 'numeric', day: 'numeric' };
  return capitalizeFirstLetter(intl.formatDate(moment(momentDate), options));
};

export const getCurrentHour = () => moment(new Date()).format('HH:mm');

export const addDays = (days, date) => dayjs(date).add(days, 'days');

/**
 * Returns a Date from a String in ISO time
 * This method takes in consideration timezones, therefore this
 * Date will have its time set on 00:00:00.000
 * @param {String}
 * @returns {Date}
 */
export const getDateFromISOTime = (dateInISOFormat) => {
  const UTCMidnight = new Date(dateInISOFormat);
  return new Date(UTCMidnight.getTime() + timeZoneOffsetInMilieconds);
};

/**
 * Returns difference between two dates following a specified format (e.g 'days')
 * @param {String} startDate
 * @param {String} endDate
 * @param {String} format
 */
export const dateDiff = (startDate, endDate, format) => {
  const start = moment(startDate);
  const end = moment(endDate);

  return end.diff(start, format);
};

// Returns epoch value in seconds
export const getDateEpochFromStartTimeInSec = (startTime, visitDate) => {
  const epoch = getEpochFromDate(startTime, visitDate);
  return epoch / EPOCH_PATH_CONSTANT_FACTOR;
};

export const formatTime = (intl, time) => {
  if (!Number.isNaN(Date.parse(time))) {
    return intl.formatTime(time);
  }
  return intl.formatTime(new Date(2000, 1, 1, ...time.split(':').map(Number)));
};

export const deriveDateFormat = (intl) => {
  const isoString = '2019/11/21'; // sample date to retrieve format
  const intlString = intl.formatDate(isoString);
  const dateParts = isoString.split('/');
  return intlString
    .replace(dateParts[2], 'dd')
    .replace(dateParts[1], 'MM')
    .replace(dateParts[0], 'YYYY');
};

/**
 * Gets the Date Time in UTC and converts to related timezone
 *
 * @param {*} utcDate Date in UTC
 * @returns Date converted to its related timezone
 */
export const getDateWithTimeZone = (utcDate) => {
  const utcDateConverted = new Date(utcDate);
  const offsetInMs = new Date().getTimezoneOffset() * 60 * 1000;
  return new Date(utcDateConverted.getTime() - offsetInMs);
};

export const formatBlockedScheduleDate = (intl, date) => {
  const options = { weekday: 'long', month: 'numeric', day: 'numeric' };
  return capitalizeFirstLetter(intl.formatDate(moment(date), options));
};

export const formatWeekDay = (intl, date, weekday = 'short') => {
  // Returns first 3 letters of weekDay with first one capitalized
  // Ex: 'Seg'
  const weekDay = intl.formatDate(date, {
    timeZone: 'UTC',
    weekday,
  });

  return capitalizeFirstLetter(weekDay.replace('.', ''));
};

export const isOfficeHour = () => {
  dayjs.extend(isBetween);

  const currentTime = dayjs();
  dayjs.extend(customParseFormat);

  const startOfficeTime = dayjs(FULLDAY_SCHEDULE.startTime, 'HH:mm');
  const endOfficeTime = dayjs(FULLDAY_SCHEDULE.endTime, 'HH:mm');
  return currentTime.isBetween(startOfficeTime, endOfficeTime, 'hour', '[)');
};

export const formatDateToMessage = ({
  intl,
  date,
  monthFormat = 'long',
  todayMessage = null,
  yesterdayMessage = null,
}) => {
  const dateMessage = capitalizeFirstLetter(
    intl.formatDate(date, {
      weekday: 'long',
      month: monthFormat,
      day: '2-digit',
      timeZone: 'UTC',
    }),
  );

  if (todayMessage && isDateToday({ date })) {
    return dateMessage.replace(/^[^\s,]+(?=,)/, todayMessage);
  }
  if (yesterdayMessage && isDateYesterday({ date })) {
    return dateMessage.replace(/^[^\s,]+(?=,)/, yesterdayMessage);
  }

  return dateMessage;
};

export const getHourFromSlot = (slot) => {
  const timeSlot = VISIT_TIMES.find((time) => time.beginSlot === slot);

  return timeSlot?.beginHour;
};

export const getFirstDayOfMonth = () => {
  const date = new Date();
  return new Date(date.getFullYear(), date.getMonth(), 1);
};

/**
 * Receive the date and the number of months ago
 * @param {int} numberOfMonths
 * @returns {date} containing the date before the number of months received
 */
export const getMonthsAgo = (date, numberOfMonths) => {
  const monthsAgo = new Date(date);
  monthsAgo.setMonth(monthsAgo.getMonth() - numberOfMonths);
  return monthsAgo;
};

/**
 * Creates a new Date object by combining a date string and a time string.
 *
 * @param {string} dateString - The date string in the format 'yyyy-MM-dd'.
 * @param {string} timeString - The time string in the format 'HH:mm'.
 * @returns {Date} - A new Date object representing the combined date and time.
 */
export const createDatetimeFromStrings = (dateString, timeString) => {
  // Split the date string into year, month, and day
  const [year, month, day] = dateString.split('-').map(Number);

  // Split the time string into hour and minute
  const [hour, minute] = timeString.split(':').map(Number);

  // Create a new Date object
  const date = new Date(year, month - 1, day, hour, minute);

  return getDateWithTimeZone(date);
};
