import {
  AGENTS_APP_SCHEME,
  INTERNAL_CHATS_URL,
  SITE_URL,
  WHATS_APP_URL,
} from 'config';
import BUSINESS_CONTEXTS from 'enums/businessContexts';
import { HOUSE_ID_PREFIX } from 'utils/constants';

/**
 * Receive a QuerySnapshot and parse to array with snapshot datas
 * @param {QuerySnapshot} snapshots
 * @returns {array} containing the snapshot datas
 */
export const parseSnapshotToArray = (snapshots) =>
  snapshots.docs.map((snapshot) => snapshot.data());

/**
 * Converts house long (9 digit) to short (5/6 digit) id
 * @param {number} longId long house id (larger than HOUSE_ID_PREFIX)
 * @return {number} short house id
 */
export const longToShortId = (longId) => longId - HOUSE_ID_PREFIX;

/**
 * Converts house short (5/6 digit) to long (9 digit) id
 * @param {number} shortId long house id (larger than HOUSE_ID_PREFIX)
 * @return {number} short house id
 */
export const shortToLongId = (shortId) => shortId + HOUSE_ID_PREFIX;

/**
 * Checks if house id is long id
 * @param {number} houseId
 */
export const isLongHouseId = (houseId) => houseId > HOUSE_ID_PREFIX;

/**
 * Converts house code to house id
 * @param  {string} code  The code we want to convert
 * @return {string}       Converted id
 */
export function calculateId(code) {
  const codeNumber = Number(code);
  return String(
    codeNumber < HOUSE_ID_PREFIX ? HOUSE_ID_PREFIX + codeNumber : codeNumber,
  );
}

export const getLongHouseId = (houseId) => {
  const castedHouseId = Number(houseId);
  return isLongHouseId(castedHouseId)
    ? castedHouseId
    : shortToLongId(castedHouseId);
};

/**
 * Returns link to house listing, based on house id
 * @param {*} houseId house id, short or long
 */
export const getListingLink = (houseId, businessContext) =>
  `${SITE_URL}/imovel/${houseId}/${
    businessContext === BUSINESS_CONTEXTS.FOR_SALE ? 'comprar' : ''
  }`;

/**
 * Returns link to house listing, based on house id and query params.
 * @param {*} houseId house id
 * @param {Object} params The object containing the params to query string
 */
export const getListingLinkWithQueryString = (houseId, params) => {
  const queryString = Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join('&');
  return `${SITE_URL}/imovel/${houseId}?${queryString}`;
};

/**
 * Round a fraction number to the number of decimals specified
 * @param {number} value The number to be rounded
 * @param {number} decimalPlaces The number of decimals to be rounded to
 * @returns {number} The rounded number
 */
export const roundFraction = ({ value, decimalPlaces }) => {
  const roundFactor = 10 ** decimalPlaces;
  return Math.round(value * roundFactor) / roundFactor;
};

/**
 * Build a deep link that opens phone call app (either web or the app)
 * @param {string} phone The phone number of the message recipient formatted as +XXXXXXXXXXXXX
 * @returns {string} The built link
 */
export const buildPhoneLink = (phoneNumber) => `tel:${phoneNumber}`;

/**
 * Build a deep link that opens WhatsApp (either web or the app)
 * @param {string} phone The phone number of the message recipient formatted as +XXXXXXXXXXXXX
 * @param {string} message The message to be sent
 * @returns {string} The built link
 */
export const buildWhatsAppLink = (phone, message) => {
  let link = `${WHATS_APP_URL}/${phone}`;

  if (message) {
    const encodedMessage = encodeURIComponent(message);
    link = `${link}?text=${encodedMessage}`;
  }
  return link;
};

/**
 * Build a link to have a chat between agent and participant
 * @param {string} chatGroupUuid The chat group uuid returned by chat-manager-api after creating a chat
 * @param {string} message The message to be sent
 * @param {boolean} isPhoneDialer The value to be sent if the call should be initiated
 * @param {boolean} isWebview Whether the PWA is running inside a webview. So we can use app scheme or web app URL to build the link.
 * @returns {string} The built link
 */
export const buildInternalChatLink = ({
  chatGroupUuid,
  message,
  isPhoneDialer,
  isWebview,
}) => {
  const linkBase = isWebview ? AGENTS_APP_SCHEME : INTERNAL_CHATS_URL;
  let link = `${linkBase}/chats/${chatGroupUuid}`;

  if (message) {
    const encodedMessage = formUrlencoded(message);
    link = `${link}?text=${encodedMessage}`;
    link = `${link}&shouldStartCall=${isPhoneDialer}`;
  } else {
    link = `${link}?shouldStartCall=${isPhoneDialer}`;
  }

  return link;
};

const removeFromSet = (set) => (value) => set.delete(value);

export const isArrayEqual = (firstArray, secondArray) => {
  const difference = new Set(firstArray);

  if (!secondArray.every(removeFromSet(difference))) {
    return false;
  }

  return !difference.size;
};

export const immutableToImmer = (selector) => (state) => selector(state.toJS());

/**
 * Format the string to the application/x-www-form-urlencoded pattern
 * @param string
 * @returns {string}
 */
export const formUrlencoded = (string) =>
  encodeURIComponent(string)
    .replace(/%20/g, '+')
    .replace(/%3A/g, ':')
    .replace(/%2C/g, ',')
    .replace(/%3F/g, '?')
    .replace(/%3D/g, '=')
    .replace(/%2F/g, '/');
