import { HTTP_METHODS } from 'config';
import agentsApiConfig from 'api/agents';
import {
  IS_AGENT,
  ALL_CLIENTS,
  CLIENTS_ATTENDANCE,
  CLIENTS_PRIORITIZATION,
  AGENT_PERFORMANCE,
  SEND_AGENT_LEAD_REFERRAL,
  AGENT_LEAD_REFERRAL,
  ENABLE_AGENT_SERVICE_LINK,
} from 'api/agents/endpoints';

/**
 * Check if current user is an agent
 *
 * @returns {boolean} - True if current user is an agent
 */
export const checkIsAgent = () =>
  agentsApiConfig({
    endpoint: IS_AGENT,
  });

export const getAllAgentClients = (params) => {
  const searchParams = new URLSearchParams();

  Object.entries(params).forEach(([key, value]) => {
    searchParams.set(key, value);
  });

  return agentsApiConfig({
    endpoint: ALL_CLIENTS,
    method: HTTP_METHODS.GET,
    queryString: searchParams.toString(),
  });
};

export const getAgentAttendanceClients = (params) => () => {
  const queryParams = new URLSearchParams();

  Object.entries(params).forEach(([key, value]) => {
    if (value) {
      queryParams.set(key, value);
    }
  });

  return agentsApiConfig({
    endpoint: CLIENTS_ATTENDANCE,
    method: HTTP_METHODS.GET,
    queryString: queryParams.toString(),
  });
};

export const getAgentClientsPrioritization = (activityDays, isPfa) => () => {
  const queryParams = new URLSearchParams();
  const shouldGetAllClientType = isPfa === null || isPfa === undefined;

  queryParams.set('activityDays', activityDays);
  if (!shouldGetAllClientType) {
    queryParams.set('isPfa', isPfa);
  }

  return agentsApiConfig({
    endpoint: CLIENTS_PRIORITIZATION,
    method: HTTP_METHODS.GET,
    queryString: queryParams.toString(),
  });
};

export const getAgentsPerformance = (startDate, endDate) => {
  const queryParams = new URLSearchParams();

  queryParams.set('startDate', startDate);
  queryParams.set('endDate', endDate);
  return agentsApiConfig({
    endpoint: AGENT_PERFORMANCE,
    method: HTTP_METHODS.GET,
    queryString: queryParams.toString(),
  });
};

/**
 * Post Agent Lead Referral Data
 */
export const postAgendLeadReferral = (payload) =>
  agentsApiConfig({
    endpoint: SEND_AGENT_LEAD_REFERRAL,
    method: HTTP_METHODS.POST,
    payload,
  });

export const getAgentLeadReferral = (page, size) =>
  agentsApiConfig({
    endpoint: AGENT_LEAD_REFERRAL,
    method: HTTP_METHODS.GET,
    queryString: `page=${page}&size=${size}`,
  });

export const postEnableServiceLink = () =>
  agentsApiConfig({
    endpoint: ENABLE_AGENT_SERVICE_LINK,
    method: HTTP_METHODS.POST,
  });
