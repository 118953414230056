import {
  AGENDA_VISIT_DETAIL_ACTION_CLICKED_ANALYTICS,
  AGENDA_VISIT_DETAIL_HOUSE_ID_COPIED_ANALYTICS,
  AGENDA_VISIT_DETAIL_LISTING_CLICKED_ANALYTICS,
  AGENDA_VISIT_DETAIL_PAGE_VIEWED_ANALYTICS,
  AGENDA_VISIT_DETAIL_CHAT_WITH_AGENT_CLICKED_ANALYTICS,
  AGENDA_VISIT_DETAIL_CHAT_WITH_OWNER_CLICKED_ANALYTICS,
  AGENDA_VISIT_DETAIL_SUGGESTION_VIEWED_ANALYTICS,
  AGENDA_VISIT_DETAIL_SUGGESTION_CLICKED_ANALYTICS,
  VISIT_CARD_CLICKED,
} from 'containers/Agenda/Detail/types';

import {
  AGENDA_PAGE_VIEWED_ANALYTICS,
  AGENDA_VISIT_CANCELLATION_CONFIRMED,
  AGENDA_VISIT_CARD_ACTION_CLICKED_ANALYTICS,
  AGENDA_NEW_VISIT_CLICKED_ANALYTICS,
  CHANGE_TAB,
  NEW_VISIT_SCHEDULE_DATE,
  AGENDA_VISIT_CANCEL_PAGE_VIEWED,
} from 'containers/Agenda/types';
import {
  AGENDA_VISIT_DIALOG_RESCHEDULE_CLICKED_ANALYTICS,
  AGENDA_VISIT_CANCELLATION_CONTINUE_CLICKED,
} from 'containers/Agenda/Dialog/CancelReason/types';
import {
  AGENDA_VISIT_DETAIL_OWNER_CONTACT_CLOSE_CLICKED_ANALYTICS,
  AGENDA_VISIT_DETAIL_OWNER_CONTACT_OPEN_CLICKED_ANALYTICS,
} from 'containers/Agenda/Detail/VisitInfo/types';

import { PAGE_NAMES } from '../constants';
import { immutablePage } from '../utils';

export const handleAgendaActions = (eventDispatcherHandler, action) => {
  switch (action.type) {
    case AGENDA_PAGE_VIEWED_ANALYTICS:
      eventDispatcherHandler.logWithDefaultEventProps(
        'agenda_visit_list_page_viewed',
        immutablePage(PAGE_NAMES.AGENDA),
      );
      break;

    case AGENDA_VISIT_DETAIL_PAGE_VIEWED_ANALYTICS:
      eventDispatcherHandler.logWithDefaultEventProps(
        'agenda_visit_detail_page_viewed',
        immutablePage(PAGE_NAMES.AGENDA),
        action.extraProperties,
      );
      break;

    case AGENDA_VISIT_DETAIL_CHAT_WITH_AGENT_CLICKED_ANALYTICS:
      eventDispatcherHandler.logWithDefaultEventProps(
        'whtsppciqtpa_agentspwa_pagevisita_clicked',
        immutablePage(PAGE_NAMES.AGENDA),
        action.extraProperties,
      );
      break;

    case AGENDA_VISIT_DETAIL_CHAT_WITH_OWNER_CLICKED_ANALYTICS:
      eventDispatcherHandler.logWithDefaultEventProps(
        'chatcrpp_access_chat_clicked',
        immutablePage(PAGE_NAMES.AGENDA),
      );
      break;

    case AGENDA_VISIT_DETAIL_HOUSE_ID_COPIED_ANALYTICS:
      eventDispatcherHandler.logWithDefaultEventProps(
        'agenda_visit_detail_houseId_copied',
        immutablePage(PAGE_NAMES.AGENDA),
      );
      break;

    case AGENDA_VISIT_DETAIL_LISTING_CLICKED_ANALYTICS:
      eventDispatcherHandler.logWithDefaultEventProps(
        'agenda_visit_detail_listing_clicked',
        immutablePage(PAGE_NAMES.AGENDA),
        action.extraProperties,
      );
      break;

    case VISIT_CARD_CLICKED:
      eventDispatcherHandler.logWithDefaultEventProps(
        'agenda_visit_card_clicked',
        immutablePage(PAGE_NAMES.AGENDA),
        action.extraProperties,
      );
      break;

    case CHANGE_TAB:
      eventDispatcherHandler.logWithDefaultEventProps(
        'agenda_day_tab_changed',
        immutablePage(PAGE_NAMES.AGENDA),
        { day_index: `d${action.payload.tabIndex}` },
      );
      break;

    case AGENDA_NEW_VISIT_CLICKED_ANALYTICS:
      eventDispatcherHandler.logWithDefaultEventProps(
        'agenda_new_visit_clicked',
        immutablePage((action.extraProperties || {}).page || PAGE_NAMES.AGENDA),
        action.extraProperties,
      );
      break;

    case AGENDA_VISIT_CANCELLATION_CONFIRMED:
      eventDispatcherHandler.logWithDefaultEventProps(
        'agenda_visit_cancellation_confirmed',
        immutablePage(PAGE_NAMES.AGENDA),
        action.extraProperties,
      );
      break;

    case AGENDA_VISIT_CANCEL_PAGE_VIEWED:
      eventDispatcherHandler.logWithDefaultEventProps(
        'visit_cancel_page_viewed',
        immutablePage(PAGE_NAMES.AGENDA),
        action.extraProperties,
      );
      break;

    case AGENDA_VISIT_CARD_ACTION_CLICKED_ANALYTICS:
      eventDispatcherHandler.logWithDefaultEventProps(
        'agenda_visit_card_action_clicked',
        immutablePage(PAGE_NAMES.AGENDA),
        action.extraProperties,
      );
      break;

    case AGENDA_VISIT_DETAIL_ACTION_CLICKED_ANALYTICS:
      eventDispatcherHandler.logWithDefaultEventProps(
        'agenda_visit_detail_action_clicked',
        immutablePage(PAGE_NAMES.AGENDA_DETALHES),
        action.extraProperties,
      );
      break;

    case AGENDA_VISIT_DETAIL_SUGGESTION_VIEWED_ANALYTICS:
      eventDispatcherHandler.logWithDefaultEventProps(
        'agenda_visit_detail_suggestion_viewed',
        immutablePage(PAGE_NAMES.AGENDA),
        action.extraProperties,
      );
      break;

    case AGENDA_VISIT_DETAIL_SUGGESTION_CLICKED_ANALYTICS:
      eventDispatcherHandler.logWithDefaultEventProps(
        'agenda_visit_detail_suggestion_clicked',
        immutablePage(PAGE_NAMES.AGENDA),
        action.extraProperties,
      );
      break;
    case NEW_VISIT_SCHEDULE_DATE:
      eventDispatcherHandler.logWithDefaultEventProps(
        'new_visit_schedule_date',
        immutablePage(PAGE_NAMES.AGENDA),
        action.extraProperties,
      );
      break;

    case AGENDA_VISIT_DIALOG_RESCHEDULE_CLICKED_ANALYTICS:
      eventDispatcherHandler.logWithDefaultEventProps(
        'agenda_visit_dialog_reschedule_clicked',
        immutablePage(PAGE_NAMES.AGENDA),
      );
      break;

    case AGENDA_VISIT_DETAIL_OWNER_CONTACT_OPEN_CLICKED_ANALYTICS:
      eventDispatcherHandler.logWithDefaultEventProps(
        'owner_contact_open_clicked',
        immutablePage(PAGE_NAMES.AGENDA),
        action.extraProperties,
      );
      break;

    case AGENDA_VISIT_DETAIL_OWNER_CONTACT_CLOSE_CLICKED_ANALYTICS:
      eventDispatcherHandler.logWithDefaultEventProps(
        'owner_contact_close_clicked',
        immutablePage(PAGE_NAMES.AGENDA),
        action.extraProperties,
      );
      break;

    case AGENDA_VISIT_CANCELLATION_CONTINUE_CLICKED:
      eventDispatcherHandler.logWithDefaultEventProps(
        'agenda_visit_cancellation_continue_clicked',
        immutablePage(PAGE_NAMES.AGENDA),
      );
      break;

    default:
      break;
  }
};

export default handleAgendaActions;
