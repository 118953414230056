const IOS_WEBVIEW = 'iOS-Webview';
const ANDROID_WEBVIEW = 'android-Webview';
const AGENTS_APP = 'Corretores';

export const AMPLITUDE_PLATFORM = {
  mobile: 'web_mobile',
  desktop: 'web_desktop',
  ios: 'ios',
  android: 'android',
};

export const isMobilePlatform = () =>
  navigator.userAgent.match(/Mobi/) !== null;

export const isWebviewOnIos = () =>
  typeof window !== 'undefined' && navigator.userAgent.includes(IOS_WEBVIEW);

export const isWebviewOnAndroid = () =>
  typeof window !== 'undefined' &&
  navigator.userAgent.includes(ANDROID_WEBVIEW);

export const useIsWebview = () => isWebviewOnIos() || isWebviewOnAndroid();

export const getPlatform = () => {
  if (isWebviewOnIos()) {
    return AMPLITUDE_PLATFORM.ios;
  }

  if (isWebviewOnAndroid()) {
    return AMPLITUDE_PLATFORM.android;
  }

  if (isMobilePlatform()) {
    return AMPLITUDE_PLATFORM.mobile;
  }

  return AMPLITUDE_PLATFORM.desktop;
};
