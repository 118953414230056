import { parseCookiesToUser } from '@quintoandar/wat-cookie';
import BUSINESS_CONTEXTS from 'enums/businessContexts';
import { REDE } from './constants';

export const getAgentRoles = () => {
  const roles = localStorage.getItem('agentRoles');
  if (roles) {
    return roles.split(',');
  }

  return [];
};

export const getAgentIdByCookie = () => {
  const userFromCookie = parseCookiesToUser();
  if (userFromCookie && userFromCookie.agent_id) {
    return userFromCookie.agent_id;
  }
  return null;
};

export const isAgentRede = (isLeadGenBroker = false) => {
  const roles = getAgentRoles();
  if (roles.length !== 0) {
    return roles.includes(REDE) && !isLeadGenBroker;
  }

  return false;
};

export const isSaleAgent5A = (businessContext, isLeadGenBroker) => {
  const isSaleAgent = businessContext === BUSINESS_CONTEXTS.FOR_SALE;
  const isRedeAgent = isAgentRede(isLeadGenBroker);

  return isSaleAgent && !isRedeAgent;
};
