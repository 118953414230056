import moment from 'moment';

import { EXTERNAL_RENTAL_ADMINISTRATORS } from 'containers/Listing/constants';
import BUSINESS_CONTEXTS from 'enums/businessContexts';
import VISIT_STATUS from 'enums/visitStatus';
import { isThirdParty } from 'utils/listing/listingSource';
import { mapStatusFromV3 } from 'utils/visits/status';
import {
  createDatetimeFromStrings,
  formatDateYearMonthDayPattern,
} from 'utils/date';

import { SPECIAL_CONDITION_TYPE } from './Detail/HouseInfo/constants';
import {
  FULLDAY_SCHEDULE,
  SCHEDULE_BLOCK_TYPES,
  SCHEDULE_TYPE,
} from './constants';

export const INCOME_PROOF_RENT_MULTIPLIER = 2.5;

export const buildTimeHouseId = (startTime, houseId) =>
  `${startTime}-${houseId}`;

const isCanceledStatus = (status) => status === VISIT_STATUS.CANCELED;

const isVisitorCanceled = (visitor) => isCanceledStatus(visitor.get('status'));

const isVisitorDeleted = (visitor) => visitor.get('isDeleted');

const visitorHasAttended = (visitor) =>
  visitor.get('hasAttended') == null || visitor.get('hasAttended');

const isVisitCanceled = (visit) => isCanceledStatus(visit.get('status'));

const filterVisitors = (visitors) =>
  visitors.filter(
    (visitor) => !isVisitorCanceled(visitor) && !isVisitorDeleted(visitor),
  );

const shouldFilterVisitors = ({ visit, visitors }) =>
  !isVisitCanceled(visit) && visitors != null && visitors.size > 1;

export const filterVisit = (visit) => {
  let visitors = visit.get('visitors', []);
  if (!shouldFilterVisitors({ visit, visitors })) {
    return visit.set('visitors', visitors);
  }

  if (!visit.get('visitNotHappen')) {
    visitors = visitors.filter(visitorHasAttended);
  }

  return visit.set('visitors', filterVisitors(visitors));
};

export const filterDeletedVisits = (visit) => !visit.get('isDeleted');

export const isOriginal = (currentCondition) => {
  if (!currentCondition) {
    return false;
  }

  const conditions = [
    SPECIAL_CONDITION_TYPE.ORIGINALS_READY,
    SPECIAL_CONDITION_TYPE.ORIGINALS_RENO,
  ];

  return conditions.includes(currentCondition);
};

export function propertyRequiredIncome(propertyTotalCost) {
  return (
    Math.round((propertyTotalCost * INCOME_PROOF_RENT_MULTIPLIER) / 100) * 100
  );
}

export const getDateDayDisplacement = (date) => {
  const currentDate = new Date().toISOString().split('T')[0];
  const selectedDate = moment(date, 'YYYY-MM-DD');
  const currentDay = moment(currentDate, 'YYYY-MM-DD');

  return selectedDate.diff(currentDay, 'days');
};

/**
 * Builds a list of grouped visits by startTime
 * @param {object} visits Each key is the startTime and the value is the visit's data
 * @returns {array} List of visit groups: [{ startTime, type, visits }]
 */
export const buildVisitList = (visits = {}) => {
  const groupedVisits = visits
    ? Object.values(visits)
        .sort(sortByHourString)
        .reduce((acc, visit) => {
          acc[visit.startTime] = [...(acc[visit.startTime] || []), visit];
          return acc;
        }, {})
    : [];

  return Object.entries(groupedVisits).map(([startTime, visitList]) => ({
    startTime,
    type: SCHEDULE_TYPE.VISIT,
    visits: visitList,
  }));
};

/**
 * Builds a list of merged blocked schedules
 * If the entire day is blocked, then it returns a single block in the list from 08:00 to 20:00
 * @param {object} temporaryBlocks The object with isEntireDay and intervals
 * @param {object} fixedBlocks The object with isEntireDay and intervals
 * @returns {array} List of blocks: [{ startTime, endTime, type, isEntireDay }]
 */
export const buildBlockList = (temporaryBlocks = {}, fixedBlocks = {}) => {
  if (fixedBlocks?.isEntireDay) {
    return [
      {
        ...FULLDAY_SCHEDULE,
        type: SCHEDULE_TYPE.FIXED_BLOCK,
      },
    ];
  }

  if (temporaryBlocks?.isEntireDay) {
    return [
      {
        ...FULLDAY_SCHEDULE,
        type: SCHEDULE_TYPE.TEMPORARY_BLOCK,
      },
    ];
  }

  return mergeTemporaryAndFixedBlocks(
    temporaryBlocks?.intervals,
    fixedBlocks?.intervals,
  );
};

/**
 * Merges the temporary and the fixed blocked schedules
 * In case of overlaps, the fixed blocks are prioritized over the temporary ones
 * @param {array} temporaryIntervals The list of temporary blocks intervals
 * @param {array} fixedIntervals The list of fixed blocks intervals
 * @returns {array} List of blocks sorted by startTime: [{ startTime, endTime, type, isEntireDay}]
 */
export const mergeTemporaryAndFixedBlocks = (
  temporaryIntervals = [],
  fixedIntervals = [],
) => {
  const blocks = [];

  const sortedBlocks = [
    ...fixedIntervals.map(addType(SCHEDULE_TYPE.FIXED_BLOCK)),
    ...temporaryIntervals.map(addType(SCHEDULE_TYPE.TEMPORARY_BLOCK)),
  ].sort(sortByHourString);

  if (sortedBlocks.length === 0) {
    return blocks;
  }

  blocks.push(sortedBlocks[0]);

  for (let i = 0; i < sortedBlocks.length; i += 1) {
    const lastBlock = blocks[blocks.length - 1];

    if (lastBlock.endTime <= sortedBlocks[i].startTime) {
      blocks.push(sortedBlocks[i]);
    } else if (lastBlock.endTime <= sortedBlocks[i].endTime) {
      if (
        lastBlock.type === SCHEDULE_TYPE.TEMPORARY_BLOCK &&
        sortedBlocks[i].type === SCHEDULE_TYPE.FIXED_BLOCK
      ) {
        lastBlock.endTime = sortedBlocks[i].startTime;
        blocks.push(sortedBlocks[i]);
      } else if (
        lastBlock.type === SCHEDULE_TYPE.FIXED_BLOCK &&
        sortedBlocks[i].type === SCHEDULE_TYPE.TEMPORARY_BLOCK
      ) {
        blocks.push({ ...sortedBlocks[i], startTime: lastBlock.endTime });
      } else {
        lastBlock.endTime = sortedBlocks[i].endTime;
      }
    } else if (
      lastBlock.endTime > sortedBlocks[i].endTime &&
      lastBlock.type === SCHEDULE_TYPE.TEMPORARY_BLOCK &&
      sortedBlocks[i].type === SCHEDULE_TYPE.FIXED_BLOCK
    ) {
      blocks.push(sortedBlocks[i]);
      blocks.push({
        type: SCHEDULE_TYPE.TEMPORARY_BLOCK,
        startTime: sortedBlocks[i].endTime,
        endTime: lastBlock.endTime,
      });
      lastBlock.endTime = sortedBlocks[i].startTime;
    }
  }

  return blocks;
};

export const addType = (type) => (schedule) => ({
  ...schedule,
  type,
});

export const sortByHourString = (previous, next) => {
  if ((previous.startTime || previous.time) < (next.startTime || next.time)) {
    return -1;
  }
  if ((previous.startTime || previous.time) > (next.startTime || next.time)) {
    return 1;
  }
  return 0;
};

/**
 * Builds the list of grouped schedules with both visits and blocks
 * @param {object} visits The key is the startTime and the value is the visit's data
 * @param {object} temporaryBlocks The object with the isEntireDay and the intervals
 * @param {object} fixedBlocks The object with the isEntireDay and the intervals
 * @returns {array} List of schedules:
 * [{ startTime, endTime, type, visits(optional), isEntireDay(optional), isWithinBlockedSchedule(optional) }]
 */
export const buildScheduleGroups = (
  visits = {},
  temporaryBlocks = {},
  fixedBlocks = {},
) => {
  const visitList = buildVisitList(visits);
  const blockList = buildBlockList(temporaryBlocks, fixedBlocks);

  let block = {};
  return [...blockList, ...visitList].sort(sortByHourString).map((schedule) => {
    const newSchedule = { ...schedule };

    if (SCHEDULE_BLOCK_TYPES.includes(schedule.type)) {
      block = schedule;
    } else if (
      schedule.startTime >= block.startTime &&
      schedule.startTime < block.endTime
    ) {
      newSchedule.isWithinBlockedSchedule = true;
    }

    return newSchedule;
  });
};

export const isManagedByExternalAdministrator = (visit) => {
  let result = false;
  if (
    visit.businessContext === BUSINESS_CONTEXTS.FOR_RENT &&
    visit.house.listings
  ) {
    visit.house.listings
      .filter(
        (listing) => listing.businessContext === BUSINESS_CONTEXTS.FOR_RENT,
      )
      .forEach((listing) => {
        if (
          EXTERNAL_RENTAL_ADMINISTRATORS.includes(
            listing.listingRentModel?.rentalAdministrator,
          )
        ) {
          result = true;
        }
      });
  }

  return result;
};

export const shuffleObject = (reasons) => {
  const newObj = {};
  const keys = Object.keys(reasons);
  keys.sort(() => Math.random() - 0.5);
  keys.forEach((k) => {
    newObj[k] = reasons[k];
  });
  return newObj;
};

export const groupObjectByKey = (items, key) =>
  items.reduce((acc, item) => {
    acc[item[key]] = [...(acc[item[key]] || []), item];
    return acc;
  }, {});

/**
 * Format the visit response from V3 to the format used in the agenda
 */
export const formatAgendaVisit = (visit) => {
  const { businessContext, singleHouseVisitDetails, visitConfiguration } =
    visit || {};
  const {
    houseDetails,
    visitTime,
    structuredStatus,
    followUp,
    tempMigrationSupport,
    actorDetails: { visitSupply, visitTenantLiving },
  } = singleHouseVisitDetails || {};

  return {
    schedulingId: tempMigrationSupport?.schedulingId,
    house: formatHouseDetails(houseDetails),
    visitors: formatVisitors(visit),
    visitDate: createDatetimeFromStrings(visitTime?.date, visitTime?.beginHour),
    formattedDate: visitTime?.date,
    startTime: visitTime?.beginHour,
    endTime: visitTime?.endHour,
    status: mapStatusFromV3(structuredStatus?.status),
    businessContext,
    visitNotHappen: followUp?.agentFollowUp?.visitNotHappen,
    isPrimaryMarket: houseDetails?.saleDetails?.isPrimaryMarket,
    isThirdPartyHouse: isThirdParty(houseDetails),
    visitConfiguration,
    structuredStatus,
    ownerId: visitSupply?.userId?.id,
    visitTenantLiving,
    ownerPhone: visitSupply?.phoneNumber,
  };
};

/**
 * Format the house details from V3 to the format used in the agenda
 */
export const formatHouseDetails = (houseDetails) => {
  const { houseId: id, shortId, type, address, image } = houseDetails || {};

  return {
    id,
    shortId,
    type,
    address: {
      street: address?.street,
      complement: address?.complement,
      number: address?.number,
      region: address?.neighborhood,
      city: address?.city,
      zipCode: address?.zipCode,
    },
    mainImageUrl: image,
  };
};

/**
 * Format the visitors from V3 to the format used in the agenda
 */
export const formatVisitors = (visit) => {
  const { code: visitCode, singleHouseVisitDetails } = visit || {};
  const { actorDetails, structuredStatus, followUp, tempMigrationSupport } =
    singleHouseVisitDetails || {};
  const { visitDemand } = actorDetails || {};

  return [
    {
      id: visitDemand?.userId?.id,
      name: visitDemand?.name,
      phone: visitDemand?.phoneNumber,
      email: visitDemand?.email,
      status: mapStatusFromV3(structuredStatus?.status),
      hasAttended: followUp?.agentFollowUp?.visitorHasAttended,
      visitCode,
      schedulingId: tempMigrationSupport?.schedulingId,
    },
  ];
};

/**
 * Return the date range to fetch the visits for the Agenda page
 * */
export const getVisitsDateRange = ({ currentTabIndex = 0, dates = [] }) => ({
  startDate: formatDateYearMonthDayPattern(dates[currentTabIndex]),
  endDate: formatDateYearMonthDayPattern(dates[currentTabIndex]),
});
