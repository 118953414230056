import React from 'react';
import { FormattedMessage } from 'react-intl';

import Box from '@quintoandar/cozy-core/Box';
import Button from '@quintoandar/cozy-core/Button';
import Typography from '@quintoandar/cozy-core/Typography';

import { SITE_URL } from 'config';
import { CRM_CONTEXT } from 'containers/Redirecting/constants';
import Image from 'static/svgs/forbidden.svg';

import messages from './messages';
import ThirdPartyForbidden from './ThirdPartyForbidden';

const goToHome = () => {
  window.location.href = SITE_URL;
};

const Forbidden = () => {
  const hasCrmContext = localStorage.getItem(CRM_CONTEXT);
  if (hasCrmContext) return <ThirdPartyForbidden />;
  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100vh"
      maxWidth={['none', '400px']}
      mx="auto"
      p={3}
    >
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        my="auto"
        textAlign="center"
      >
        <Box
          component={Image}
          display="block"
          maxWidth="200px"
          mb={4}
          mx="auto"
          width="100%"
        />
        <Typography level="bodyStrong">
          <FormattedMessage {...messages.title} />
        </Typography>
      </Box>

      <Button fullWidth onClick={goToHome} data-testid="back-to-home-button">
        <FormattedMessage {...messages.back} />
      </Button>
    </Box>
  );
};

export default Forbidden;
