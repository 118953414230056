/**
 * Enum of user types
 * @type {{LANDLORD: string, TENANT: string, CIQ: string}}
 */
export const USER_TYPES = Object.freeze({
  LANDLORD: 'landlord',
  TENANT: 'tenant',
  CIQ: 'CIQ',
});

/**
 * Number of decimal places in rating
 * @type {number}
 */
export const RATING_DECIMAL_PLACES = 2;

/**
 * Number of elements queried each time
 * @type {number}
 */
export const ELEMENTS_PER_PAGE = 10;

/**
 * Maximum number of days in the past that the app shows
 * @type {number}
 */
export const LIMIT_OF_DAYS = 30;

/**
 *  Default number of days of visit filter by date range
 */
export const DEFAULT_DATE_PERIOD = 60;

/**
 *  Default number of days for contracts page
 */
export const DEFAULT_CONTRACTS_DATE_PERIOD = 30;

/**
 *  Default number of days for documentation page
 */
export const DEFAULT_DOCUMENTATION_DATE_PERIOD = 30;

/**
 *  Maximum number of days allowed to filter visits by date
 */
export const MAX_VISIT_DATE_PERIOD = 90;

/**
 *  Maximum number of days allowed to filter contracts by date
 */
export const MAX_CONTRACT_DATE_PERIOD = 90;

export const FILTERS_TAB_HEIGHT = 40;

/**
 * Enum of HTTP methods
 * @type {Readonly<{GET: string, POST: string, PUT: string, DELETE: string, PATCH: string}>}
 */
export const HTTP_METHODS = Object.freeze({
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
  PATCH: 'PATCH',
});

// e.g. corretor.forno.quintoandar.com.br
const baseDomainRegex =
  /(?<baseDomain>quintoandar\.com\.br|full\.5adev\..*|full\.inmuebles24\..*|5adev\..*|benvi\..*)/;
export const baseDomainMatch = window.location.hostname.match(baseDomainRegex);

// e.g. quintoandar.com.br
const baseDomain = baseDomainMatch
  ? baseDomainMatch.groups.baseDomain
  : process.env.COOKIE_DOMAIN;

export const checkPrefixAllow = () => {
  const isMxDomain = /full\.inmuebles24/g.test(window.location.hostname);

  return !isMxDomain && process.env.SITE_PREFIX;
};

export const getBaseDomain = () =>
  baseDomainMatch
    ? baseDomainMatch.groups.baseDomain
    : process.env.COOKIE_DOMAIN;

// e.g. forno.quintoandar.com.br
const domain = process.env.ENV_PREFIX
  ? `${process.env.ENV_PREFIX}.${baseDomain}`
  : baseDomain;

/**
 * Agents API URL
 * @type {string}
 */
export const AGENTS_API_URL = `https://apigw.${domain}/agents-api`; // eslint-disable-line prefer-destructuring

/**
 * Agents PWA URL
 * @type {string}
 */
export const AGENTS_PWA_URL = `https://corretor.${getBaseDomain()}`;

// Maximum container width for large screens
export const CONTAINER_WIDTH = 500;

/**
 * Number of days to be added to calculate the deadline for send/resend documentation
 * @type {number}
 */
export const SEND_DOCUMENTATION_THRESHOLD = 3;

/**
 * Link to Indica Ai app landing page.
 * @type {string}
 */
export const LINK_INDICA_AI =
  'https://mkt.quintoandar.com.br/indica-ai/?__uncache=2018-4-4%2022%3A33%3A18';

/**
 * URL to ErrorPage image (eita)
 * @type {string}
 */
export const ERROR_PAGE_IMAGE_URL =
  'https://www.quintoandar.com.br/f685019f953b1dde2e0b9475ce7a8e15.svg';

/**
 * Default number of days between the visit and current date
 * Used to generate filters labels for: (`in the last X days`)
 * @type {number}
 */
export const TIME_SINCE_VISIT = 7;

/**
 * Default number of days between the offer draft creation and current date
 * Used to generate filters labels for: (`in the last X days`)
 * @type {number}
 */
export const TIME_SINCE_OFFER_DRAFT = 10;

/**
 * link to visits view in PWA.
 * @type {string}
 */
export const AGENDA = '/agenda';

/**
 * Cookie domain
 * @type {string}
 */
export const COOKIE_DOMAIN = baseDomain; // eslint-disable-line prefer-destructuring

/**
 * Deep link to clubeQuinto page.
 * @type {string}
 */
export const CLUBE_QUINTO_URL = process.env.CLUBE_QUINTO_URL; // eslint-disable-line prefer-destructuring

/**
 * Agents app scheme. Used to open the app from the web through deeplinks.
 * @type {string}
 */
export const AGENTS_APP_SCHEME = 'agents-5a://';

/**
 * Site URL
 * @type {string}
 */
export const SITE_URL =
  checkPrefixAllow() && baseDomainMatch
    ? `${process.env.SITE_PREFIX}.${getBaseDomain()}`
    : `https://${getBaseDomain()}`;

/**
 * Entry info URL
 * @type {string}
 */
export const ENTRY_INFORMATION_URL =
  'https://corretor.quintoandar.com.br/informacoes-de-entrada'; // eslint-disable-line prefer-destructuring

/**
 * My account link
 * @type {string}
 */
export const MY_ACCOUNT_LINK = `${SITE_URL}/meus-dados`;

export const BETA_LINK = 'https://beta-corretor.quintoandar.com.br';

/**
 * Traz Quem Compra program URL
 * @type {string}
 */
export const TRAZ_QUEM_COMPRA_URL = '/traz-quem-compra';

/**
 * Indica Ai URL
 * @type {string}
 */
export const REFERRAL_PROPERTY_URL = 'https://indicaai.quintoandar.com.br';

/**
 * Internal Chats URL
 * @type {string}
 */
export const INTERNAL_CHATS_URL = process.env.INTERNAL_CHATS_URL; // eslint-disable-line prefer-destructuring

/**
 * WhatsApp URL
 * @type {string}
 */
export const WHATS_APP_URL = 'https://wa.me';

/**
 * Rent Search URL
 * @type {string}
 */
export const RENT_SEARCH_URL = `${SITE_URL}/alugar/imovel`;

/**
 * Sale Search URL
 * @type {string}
 */
export const SALE_SEARCH_URL = `${SITE_URL}/comprar/imovel`;
